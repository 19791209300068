<template>

  <div class="header">
    <ul class="status-container">
      <li class="status-item" :class="{active:coupon_type==0}" @click="chgCouponType(0)">
        <span class="status-name">未使用</span>
        <span class="selected-line"></span>
      </li>
      <li class="status-item" :class="{active:coupon_type==1}" @click="chgCouponType(1)">
        <span class="status-name">已使用</span>
        <span class="selected-line"></span>
      </li>
      <li class="status-item" :class="{active:coupon_type==2}" @click="chgCouponType(2)">
        <span class="status-name">已过期</span>
        <span class="selected-line"></span>
      </li>
    </ul>
  </div>

  <div class="data-container">
    <div class="data-list">

    </div>
    <div class="no-data">
      <img src="https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_no_coupon.png">
      <span class="no-data-desc">暂无优惠券</span>
    </div>
  </div>

</template>

<script>
import {ref} from "vue";

export default {
  name: "Coupon",
  setup(){
    let coupon_type = ref(0)  //0未使用， 1已使用， 2已过期

    function chgCouponType(val){
      coupon_type.value = val
    }

    return {
      coupon_type,
      chgCouponType
    }

  }
}
</script>

<style scoped>

.status-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-top: 4px;
  background-color: #ffffff;
}

.status-item{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.status-name{
  color: #666666;
  margin-bottom: 6px;
}

.active .status-name{
  color: #2f2f2f;
  font-weight: 600;
}

.selected-line{
  display: inline-block;
  width: 30px;
  height: 2px;
}

.active .selected-line{
  background-color: #28c873;
  border-radius: 2px;
}



/*********** data-container ***********/
.data-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.no-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 150px;
}

.no-data img{
  width: 50%;
}

.no-data-desc{
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
}


</style>
