<template>
<!--  菜单：好玩页-->

  <div class="menu">

    <!--头部-->
    <PlayHeader></PlayHeader>

    <!--排序-->
    <div class="sort-menu-container">
      <span class="item" :class="{'active': current_sort_type == 0,}" @click="getProducts(0)">精品推荐</span>
      <span class="item" :class="{'active': current_sort_type == 1,}" @click="getLocation(1)">距离最近</span>
      <span class="item" :class="{'active': current_sort_type == 2,}" @click="getProducts(2)">销量最多</span>
      <span class="item" :class="{'active': current_sort_type == 3,}" @click="getProducts(3)">无需预约</span>
    </div>

    <!--产品列表-->
    <div class="product-container">

      <ProductItem v-for="productInfo of productList" :key="productInfo.id" :product="productInfo"></ProductItem>

    </div>

    <!--底部菜单-->
    <FooterMenu></FooterMenu>

  </div>

</template>

<script>

import wx from 'weixin-js-sdk'

import ProductItem from "../components/ProductItem";
import FooterMenu from "../components/FooterMenu";
import PlayHeader from "../components/PlayHeader";
import {onMounted, onUnmounted, reactive, ref} from "vue";
import api from "../api";
import {fenToYuan} from "../util/util";

export default {
  name: "Play",
  components: {PlayHeader, FooterMenu, ProductItem},
  setup() {


    //这里获取地理位置 begin ************************
    //第一步，加载config
    //加载微信config end
    let current_url = window.location.href
    api.getJsapiData(current_url).then(rsp => {
      //加载微信config begin:
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: rsp.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
        timestamp: rsp.timestamp, // 必填，生成签名的时间戳
        nonceStr: rsp.nonceStr, // 必填，生成签名的随机串
        signature: rsp.signature,// 必填，签名，见附录1
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'previewImage',
          'getLocalImgData',
          'downloadImage',
          'uploadImage',
          'chooseImage',
          'openLocation',
          'getLocation',
          'hideMenuItems'
        ]
        // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });

      // config信息验证成功会执行ready函数
      wx.ready(() => {
        console.log("This is wx.ready.")
      })

      // config信息验证失败会执行error函数
      wx.error(function (res) {
        console.log("This is wx.error.")
        console.log(res)
      });

      //加载微信config end
    })

    //第二步，触发获取位置的具体操作
    //获取位置成功的同时，获取产品列表，参数sort_type觉得如何获取产品列表
    function getLocation(sort_type) {

      wx.getLocation({
        type: 'gcj02', //返回可以用于wx.openLocation的经纬度
        success(res) {
          //成功是，res为： {latitude: 34.86426, longitude: 117.55601, errMsg: "getLocation:ok"}
          //授权成功：成功后保存粉丝的经纬信息（保存经纬度信息成功后，再重新调一次api获取产品信息）
          api.updateLnglat(res.longitude, res.latitude).then(rsp => {
            console.log(rsp)
            getProducts(sort_type)
          })
        },
        fail: function (err) {
          console.log("err is :")
          console.log(err)
        },
        complete: function (e) {
          //不论成功与失败，都会执行这里
          //客户同意授权时， e为：{latitude: 34.86426, longitude: 117.55601, errMsg: "getLocation:ok"}
          //客户拒绝授权时： e为：{errMsg: "getLocation:cancel"}

          console.log("complete is :")
          console.log(e)

          //客户取消授权（授权失败后，强制按照“精品推荐”获取产品列表）
          if (e.errMsg.includes("cancel"))
          {
            getProducts(0)
          }
        }
      })

    }
    //这里获取地理位置 end ************************

    let current_sort_type = ref(0)

    const productList = reactive([])


    function getProducts(sort_type){


      //如果切换了排序方式，则从第一页开始取数据
      if(current_sort_type.value != sort_type)
      {
        offset_page.value = 0
      }

      //如果当前页是第一页(页码为0)，则清空原有数据； 否则，不能清空(为加载更多数据)
      if(offset_page.value == 0)
      {
        productList.splice(0, productList.length)
      }


      current_sort_type.value = sort_type

      //客户选择了：无需预约
      if(3 == sort_type){
        api.selectProductPageList({
          menu_type: 2,
          appointment: 1,
          offset:offset_page.value * page_size.value,
          pageSize:page_size.value
        }).then(rsp => {
          productList.push(...rsp.list)

          for(var product of productList) {
            product.sale_price_min_yuan = fenToYuan(product.sale_price_min)
            product.old_price_min_yuan = fenToYuan(product.old_price_min)
          }

          //设置分页的参数
          total_num.value = rsp.total
          no_more_data.value = total_num.value == productList.length
          is_loading.value = false

        })
      }
      //客户选择了：距离最近 或 销量最多 的排序
      else
      {
        api.selectProductPageList({
          menu_type: 2,
          sort_type: sort_type,
          offset:offset_page.value * page_size.value,
          pageSize:page_size.value
        }).then(rsp => {
          productList.push(...rsp.list)

          for(var product of productList) {
            product.sale_price_min_yuan = fenToYuan(product.sale_price_min)
            product.old_price_min_yuan = fenToYuan(product.old_price_min)
          }

          //设置分页的参数
          total_num.value = rsp.total
          no_more_data.value = total_num.value == productList.length
          is_loading.value = false

        })
      }

    }



    //实现触底加载数据的功能 begin ***************************************
    let is_loading = ref(false)   //是否正在加载数据：false 没有， true 正在加载

    let no_more_data = ref(false)  //没有有更多数据：false 有更多数据，true 没有更多数据
    let total_num = ref(0)        //总数量
    let offset_page = ref(0)      //当前页码
    let page_size = ref(10)       //每页数量



    onMounted(()=>{
      console.log('---onMounted---')
      window.addEventListener('scroll', reachBottom);  //页面加载时监听滚动事件
    })

    onUnmounted(()=>{
      console.log('---onUnmounted---')
      window.removeEventListener('scroll', reachBottom) //页面离开后销毁监听事件
    })

    function reachBottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      //距离底部小于50就会触发
      if (scrollTop + clientHeight >= scrollHeight - 50) {

        //开始加载分页数据
        getMoreData()

      }
    }

    function getMoreData() {

      if(no_more_data.value || is_loading.value){
        return
      }

      is_loading.value = true

      //只有总量大于0时，才递增页码 （这里的判断是为了防止，刚进入该页面就调用该方法，即尚未调用getProducts获取第一页数据，就直接调用getMoreData方法）
      if(total_num.value > 0)
      {
        offset_page.value++
      }

      getProducts(current_sort_type.value)

    }
    //实现触底加载数据的功能 end ***************************************


    //进入该页面，首次获取产品列表数据
    getProducts(0)

    return {
      productList,
      getProducts,
      current_sort_type,
      getLocation
    }

  }
}
</script>

<style scoped>

.menu{
  padding-bottom: 70px;
}


/*排序*/
.sort-menu-container{
  display: flex;
  align-items: center;
  color: #333333;
  margin-top: 10px;
}

.item{
  width: 25%;
  height: 48px;
  line-height: 48px;
  text-align: center;
}

.sort-menu-container .active{
  font-size: 16px;
  font-weight: 700;
  background: url('../assets/images/icon_learn_selected.c2ffbed4.png') no-repeat bottom center;
  background-size: 80%;
}


</style>
