/*
直接更新state的多个方法的对象
 */
export default {

    // RECEIVE_TOP1_PRODUCT(state, value){
    //     state.top1Product = value
    // },






}
