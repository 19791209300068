<template>

  <!--搜索框-->
  <div @click="gotoSearch" class="search-box">
    <img src="@/assets/images/icon_home_search@3x.png">
    <span class="search-recommend">搜你想搜的!</span>
    <span>&nbsp;</span>
    <span class="search-btn">搜索</span>
  </div>

</template>

<script>

import {useRouter} from 'vue-router'

export default {
  name: "SearchLine",
  setup(){

    const router = useRouter()

    function gotoSearch(){
      router.push('/search')
    }

    return {
      gotoSearch
    }
  }
}
</script>

<style scoped>

/****************** 搜索框 ******************/
.search-box{
  display: flex;
  align-items: center;
  height: 34px;
  /*margin-top: 4px;*/
  margin: 4px 0 0 0;
  background-color: #fff;

  /*border: 1px solid #ffbe00;*/
  border-radius: 17px;
}

.search-box img{
  width: 20px;
  margin-left: 10px;
}

.search-box :nth-child(3){
  flex: 1;
}

.search-recommend{
  height: 25px;
  font-size: 12px;
  color: #999999;
  line-height: 25px;
  margin-left: 10px;
}

.search-btn{
  width: 48px;
  height: 26px;
  /*background-color: #1f60e1;*/
  /*background-color: #ffc82d;*/
  background: linear-gradient(to right, #ffdc4e, #ffc82d);
  color: #000000;
  /*background-color: #76a3fb;*/
  /*color: #ffffff;*/
  font-size: 12px;
  border-radius: 25px;
  text-align: center;
  line-height: 26px;
  margin-right: 4px;
}

</style>
