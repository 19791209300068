<template>

    <swiper class="swiper-container"
        :slides-per-view="1"
        :space-between="50"
        :pagination="{ clickable: true }"
        :autoplay="{ autoplay: true }"
        loop
    >

      <swiper-slide v-for="(pic,index) of banner_list" :key="index"  class="swiper-slide"><img :src="pic"></swiper-slide>
<!--      <swiper-slide class="swiper-slide"><img src="@/assets/images/banner2.png"></swiper-slide>-->
<!--      <swiper-slide class="swiper-slide"><img src="@/assets/images/banner3.png"></swiper-slide>-->
<!--      <swiper-slide class="swiper-slide"><img src="@/assets/images/banner4.png"></swiper-slide>-->
<!--      <swiper-slide class="swiper-slide"><img src="@/assets/images/banner5.png"></swiper-slide>-->
<!--      <swiper-slide class="swiper-slide"><img src="@/assets/images/banner6.png"></swiper-slide>-->
      <div class="swiper-pagination"></div>
    </swiper>


</template>

<script>
//引入swiper组件
import { Swiper, SwiperSlide } from 'swiper/vue';

//引入swiper样式（swiper6的样式引入方法）
import "swiper/swiper-bundle.css";

import api from "../api"

import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay} from "swiper";
import {reactive} from "vue";

//使用swiper组件
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);


export default {
  name: "ProductSwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  props:{
    productno:String
  },
  setup(props) {

    const banner_list = reactive([])

    api.getProductBanners({
      productno:props.productno
    }).then(rsp=>{
      for(let item of rsp) {
        banner_list.push(item.pic_url)
      }
    })

    return {
      banner_list,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
}
</script>

<style scoped>


/***************** swiper ******************/

.swiper-container {
  width: 100%;
  overflow: hidden;
  --swiper-theme-color: #fff;
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 260px;
  object-fit: cover;
}



</style>
