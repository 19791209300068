<template>
<!--  适用门店列表页-->

  <div class="stores-container">
    <div class="book-container" v-if="phone != null && phone != ''">
      <div class="desc">
        <i class="iconfont icon-xiaolaba"></i>
        <span>请拨打门店电话预约（需提供预留手机号）</span>
      </div>
      <div class="user-container">
        <div class="user-title">下单时的预留信息：{{phone}}</div>
      </div>
    </div>

    <div class="head">适用门店</div>
    <ul>
      <li class="shop-item" v-for="store_info of store_list" :key="store_info.id">
        <StoreItem :store_info="store_info"></StoreItem>
      </li>
    </ul>

  </div>

</template>

<script>
import {useRoute} from 'vue-router';
import StoreItem from "../components/StoreItem";
import {reactive, ref} from "vue";
import api from "../api";
export default {
  name: "StoreList",
  components: {StoreItem},
  setup(){

    const route = useRoute ()
    let productno = ref(route.query.productno)
    let phone = ref(route.query.phone)

    //商户的门店信息
    const store_list = reactive([]);
    let store_count = ref(0)
    api.getStoresByProduct({
      productno: productno.value,
      num: 1000
    }).then(rsp => {
      store_list.push(...rsp.store_list)
      store_count.value = rsp.count
    })

    return {
      store_list,
      phone
    }

  }
}
</script>

<style scoped>

.stores-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
}

.head{
  font-size: 16px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #ededed;
}

.shop-item{
  margin: 10px 10px;
  padding: 15px 10px;
  background-color: #ffffff;
  border-radius: 10px;
}


/*预留信息*/
.book-container{
  display: flex;
  flex-direction: column;
}
.desc{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  background-color: #fdf6ec;
  color: #e6a23c;
}

.desc i{
  margin-right: 10px;
}

.user-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin: 4px 10px 10px 10px;

  font-size: 14px;
}

.user-title{
  font-weight: 600;
  height: 40px;
  line-height: 40px;
}

</style>
