/*
状态对象
 */
export default {
    top1Product:{},
    top2Product:{},
    homeProductList:[], //首页的产品列表
    jsapiData:[],       //jsapi数据
    flashSale:{},       //秒杀产品
}
