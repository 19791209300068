<template>

  <div class="hot-container">
    <div class="title-container">
      <span>爆品排行榜</span>
      <img v-if="isShowMore" src="@/assets/images/icon_hot_more.png" alt="" class="more">
    </div>
    <ul class="product-container">
      <li class="top1" @click="toProductDetail(top1_pno)">
        <img class="product-pic" :src="top1_pic" alt="">
        <div class="pname">
          {{top1_title}}
        </div>
        <img class="top-tag" src="@/assets/images/icon_hot_top1.png" alt="">
      </li>
      <li class="top2" @click="toProductDetail(top2_pno)">
        <img class="product-pic" :src="top2_pic" alt="">
        <div class="pname">
          {{top2_title}}
        </div>
        <img class="top-tag" src="@/assets/images/icon_hot_top2.png" alt="">
      </li>
    </ul>
  </div>



</template>

<script>

import {useRouter} from 'vue-router'
import {ref} from "vue";
import {refreshSitenoAndFansno} from "../util/util"

import api from '../api'

export default {
  name: "HotProduct",
  props:{
    siteno:String,
    fansno:String
  },
  setup(props){

    refreshSitenoAndFansno(props.siteno, props.fansno)

    const router = useRouter()

    let top1_pno = ref()
    let top1_pic = ref()
    let top1_title = ref()
    let top2_pno = ref()
    let top2_pic = ref()
    let top2_title = ref()
    api.getTopProducts().then(rsp => {

      if(null != rsp && undefined != rsp){
        top1_pno.value = rsp.top1.productno
        top1_pic.value = rsp.top1.pic_url
        top1_title.value = rsp.top1.top_title
        top2_pno.value = rsp.top2.productno
        top2_pic.value = rsp.top2.pic_url
        top2_title.value = rsp.top2.top_title
      }


    })

    function toProductDetail(productno){
      router.push(`/product?productno=${productno}`)
    }

    let isShowMore = ref(false)

    return {
      top1_pno,
      top1_pic,
      top1_title,
      top2_pno,
      top2_pic,
      top2_title,
      toProductDetail,
      isShowMore
    }

  }
}
</script>

<style scoped>


.hot-container{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.title-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #222222;
  padding: 20px 10px 16px 10px;
  height: 30px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: linear-gradient(#f5e1ff, #faf1ff, #ffffff);
}

.title-container img{
  width: 26px;
}

.product-container{
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.top1, .top2{
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
}

.top1{
  margin: 0 6px 0 10px;
}

.top2{
  margin-right: 10px;
}

.product-pic{
  width: 100%;
  border-radius: 8px;
}

.pname{
  font-size: 12px;
  font-weight: 500;
  color: #222222;
  width: 100%;
  height: 36px;
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-tag{
  position: absolute;
  width: 25%;

}



</style>
