/*
通过mutation间接更新state的多个方法的对象
 */

export default {

    // // 异步获取首页产品信息
    // async getHomeProductsPageList(context) {
    //
    //     const products = await api.selectHomeProductsPageList()
    //
    //     // 提交到mutation
    //     context.commit('RECEIVE_TOP1_PRODUCT', products);
    // },

}
