<template>

  <div class="footer-menu-container">
    <div class="menu-item" @click="gotoPage('/home', 'home')">
      <img :src="menus.home_pic" alt="">
      <span :class="{'selected': current_menu_name == 'home'}">{{menus.home_name}}</span>
    </div>
    <div class="menu-item" @click="gotoPage('/food', 'food')">
      <img :src="menus.food_pic" alt="">
      <span :class="{'selected': current_menu_name == 'food'}">{{menus.food_name}}</span>
    </div>
    <div class="menu-item" @click="gotoPage('/play', 'play')">
      <img :src="menus.play_pic" alt="">
      <span :class="{'selected': current_menu_name == 'play'}">{{menus.play_name}}</span>
    </div>
    <div class="menu-item" @click="gotoPage('/user', 'user')">
      <img :src="menus.user_pic" alt="">
      <span :class="{'selected': current_menu_name == 'user'}">{{menus.user_name}}</span>
    </div>
  </div>

</template>

<script>

import {useRouter} from 'vue-router'
import {ref, reactive} from "vue";

export default {
  name: "FooterMenu",
  setup(){

    const router = useRouter()


    let current_menu_name = ref(null)
    const menus = reactive({
      home_pic : "https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_footer_menu_1.png",
      home_name: "首页",
      food_pic : "https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_footer_menu_2.png",
      food_name: "好吃",
      play_pic : "https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_footer_menu_3.png",
      play_name: "好玩",
      user_pic : "https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_footer_menu_4.png",
      user_name: "我的",
    })
    switch (router.currentRoute.value.path)
    {
      case "/":
        current_menu_name = "home";
        menus.home_pic = "https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_footer_menu_1_selected.png";
        break;
      case "/home":
        current_menu_name = "home";
        menus.home_pic = "https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_footer_menu_1_selected.png";
        break;
      case "/food":
        current_menu_name = "food";
        menus.food_pic = "https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_footer_menu_2_selected.png";
        break;
      case "/play":
        current_menu_name = "play";
        menus.play_pic = "https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_footer_menu_3_selected.png";
        break;
      case "/user":
        current_menu_name = "user";
        menus.user_pic = "https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_footer_menu_4_selected.png";
        break;
    }



    function gotoPage(path, new_menu_name){

      console.log("gotoPay:" + new_menu_name)
      // console.log(menus)

      //updateMenuPic(new_menu_name)

      // console.log("gotoPay 2")
      // console.log(menus)

      router.replace(path)
    }


    return {
      current_menu_name,
      menus,
      gotoPage
    }
  },
  onBeforeMount(){
    console.log("==========001")
  }
}
</script>

<style scoped>

.footer-menu-container{
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  max-width: 540px;
  min-width: 320px;
  width: 100%;
  height: 52px;
  background-color: #ffffff;

  border-top: 1px solid #d2d2d2;

  z-index: 9;
}

.menu-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24%;
  height: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.menu-item img{
  width: 15px;
  margin-bottom: 4px;
}

.menu-item .selected{
  color: #d49f09;
  font-weight: 600;
}

</style>
