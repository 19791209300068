<template>

  <div class="uCode-container">
    <div class="desc">
      <i class="iconfont icon-xiaolaba"></i>
      <span>请妥善保管好自己的核销码！</span>
    </div>
<!--    <div class="user-container">-->
<!--      <div class="user-title">下单时的预留信息：{{phone}}</div>-->
<!--      <div class="user-title">消费码：3524</div>-->
<!--    </div>-->
    <div class="user-container">
      <div class="user-tip">使用时，请将核销码展示给商家</div>
    </div>
    <div class="uCode">
      <img :src="used_code_url" alt="">

    </div>

    <div class="care-tip">
      <span>重要提示！</span>
      <span>请不要向其他人泄露自己的核销码，防止盗用！</span>
      <span>核销码被盗用后，无法索赔，请知晓！</span>
    </div>

  </div>


</template>

<script>

import {useRoute } from 'vue-router';
import {ref} from "vue";

export default {
  name: "UsedCode",
  setup(){

    const route = useRoute ()
    let used_code_url = ref(route.query.used_code_url)

    return {
      used_code_url
    }
  }
}
</script>

<style scoped>

/*预留信息*/
.uCode-container{
  display: flex;
  flex-direction: column;
}
.desc{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  background-color: #fdf6ec;
  color: #e6a23c;
}

.desc i{
  margin-right: 10px;
}

.user-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin: 10px 10px 10px 10px;

  font-size: 14px;
}

.user-title{
  font-weight: 600;
  height: 36px;
  line-height: 36px;
}

.user-tip{
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
}

.uCode{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uCode img{
  width: 90%;
}

.care-tip{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin: 10px 20px 10px 10px;
  padding: 10px 0;

  font-size: 16px;
  color: #d9396b;

}

.care-tip span{
  height: 30px;
  line-height: 30px;
}

</style>
