<template>

  <div class="data-container">
    <ul class="data-list" v-if="product_num > 0">
      <li class="data-item"  v-for="productInfo of product_list" :key="productInfo.id" @click="toProductDetail(productInfo.productno)">
        <div class="product-info">
          <div class="product-pic" :style="{backgroundImage: 'url(' + productInfo.cover_pic + ')'}"></div>
          <div class="info">
            <span class="title">{{productInfo.title}}</span>
            <span v-if="productInfo.status == 0" class="tag-online">即将上线</span>
            <span v-if="productInfo.status == 1" class="tag-qgz">抢购中</span>
            <span v-if="productInfo.status == 2" class="tag-out-sale">已售罄</span>
            <span v-if="productInfo.status == 3" class="tag-offline">已下架</span>

            <div class="price-container">
              <span class="price-tag num-font">¥</span>
              <span class="price">{{productInfo.sale_price_min_yuan}}</span>
              <span class="shop-price">门市价 {{productInfo.old_price_min_yuan}}</span>
              <span style="flex: 1"></span>
              <span class="sale-num">销量 {{productInfo.all_sale_num}}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-else class="no-data">
      <img src="https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_no_collection.png">
      <span class="no-data-desc1">暂时没有收藏</span>
      <span class="no-data-desc2">~ 块去收藏看看 ~</span>
    </div>
  </div>

</template>

<script>
import {useRouter} from 'vue-router'
import api from "../../api"
import {fenToYuan} from "../../util/util";
import {reactive, ref} from "vue";

export default {
  name: "Collection",
  setup(){

    const router = useRouter()

    function toProductDetail(productno){
      router.push(`/product?productno=${productno}`)
    }

    const product_list = reactive([]);
    let product_num = ref(0)
    api.getProductCollectionRecords().then(rsp => {
      //首先清空原有数据
      product_list.splice(0, product_list.length)

      product_list.push(...rsp)

      for(var product of product_list) {
        product.sale_price_min_yuan = fenToYuan(product.sale_price_min)
        product.old_price_min_yuan = fenToYuan(product.old_price_min)
      }

      product_num.value = product_list.length

      console.log('============0099')
      console.log(product_num.value)

    })

    return {
      toProductDetail,
      product_list,
      product_num
    }
  }
}
</script>

<style scoped>

/*********** data-container ***********/
.data-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.no-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 150px;
}

.no-data img{
  width: 50%;
}

.no-data-desc1{
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
}

.no-data-desc2{
  color: #999999;
}

/*产品信息*/
.product-info{
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px dashed #e2e2e2;
  background-color: #ffffff;
  border-radius: 10px;
}

.product-pic{
  width: 90px;
  height: 90px;
  border-radius: 8px;
  flex-shrink: 0;
  margin-left: 10px;
  margin-right: 10px;
  background: bottom center no-repeat;
  background-size: cover;
}

.info{
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.title{
  font-size: 16px;
  font-weight: 600;
  color: #1f1f1f;
  margin-bottom: 10px;
  margin-right: 10px;

  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
  word-break:break-all;

}

.tag-qgz{
  width: 60px;
  background-color: #00af48;
  color: #ffffff;
  font-size: 12px;
  padding: 0 6px;
  border-radius: 12px;
  height: 24px;
  line-height: 24px;
  text-align: center;
}

.tag-out-sale{
  width: 60px;
  background-color: #f75151;
  color: #ffffff;
  font-size: 12px;
  padding: 0 6px;
  margin-right: 4px;
  border-radius: 12px;
  height: 24px;
  line-height: 24px;
  text-align: center;
}


.tag-offline{
  width: 60px;
  background-color: #999999;
  color: #ffffff;
  font-size: 12px;
  padding: 0 6px;
  margin-right: 4px;
  border-radius: 12px;
  height: 24px;
  line-height: 24px;
  text-align: center;
}


.tag-online{
  width: 60px;
  background-color: #fdd051;
  color: #004200;
  font-size: 12px;
  padding: 0 6px;
  margin-right: 4px;
  border-radius: 12px;
  height: 24px;
  line-height: 24px;
  text-align: center;
}


.price-container{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 10px;
  color: #666666;
  font-size: 12px;
  margin-right: 20px;
}

.price-tag{
  color: #ff4b3b;
  font-size: 12px;
  font-weight: 500;
  margin-right: 4px;
}

.price{
  color: #ff4b3b;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}

.shop-price{
  text-decoration: line-through;
}

</style>
