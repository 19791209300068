<template>
<!--  产品详情页-->

  <div class="product-container">

    <ProductSwiper :productno="productno"></ProductSwiper>

    <!--头部-->
    <div class="head">
      <div class="price-container">
        <span class="price-tag num-font">¥</span>
        <span class="price num-font">{{current_product_sale_price}}</span>
        <span class="shop-price num-font"> 门市价 ¥{{current_product_shop_price}} </span>
        <span class="sell-num">已售 {{product_info.all_sale_num}}</span>
        <span>&nbsp;</span>
        <div class="collection" @click="collectionProduct">
          <img v-if="isCollection" src="@/assets/images/collection_ok.png" alt="收藏">
          <img v-else src="@/assets/images/collection.png" alt="收藏">
        </div>
      </div>
      <ul class="tag">
        <li v-for="(tag,index) in product_info.tag_list" :key="index">{{tag}}</li>
      </ul>
      <div class="title">{{product_info.title}}</div>
      <div class="info">{{product_info.description}}</div>
    </div>

    <!-- 购买者 -->
<!--    <div class="buyer-container" v-if="product_info.status == 1">-->
<!--      <div class="buyer-title">-->
<!--        <span>已经购买的小伙伴</span>-->
<!--        <span>数量有限，先到先得</span>-->
<!--      </div>-->
<!--      <BuyerSwiper :productno="productno" :packageid="current_package_id"></BuyerSwiper>-->
<!--    </div>-->

    <BuyerSwiper v-if="product_info.status == 1" :productno="productno" :packageid="current_package_id"></BuyerSwiper>

    <!-- 选择套餐 -->
    <div class="package-container">
      <span>选择套餐</span>
      <ul class="packages" v-for="package_info in package_list" :key="package_info.id">
        <li class="package-item" :class="{'selected': current_package_id == package_info.id}" @click="selectPackage(package_info.id)">
          <div class="package-info">
            <div class="package-price">
              <span class="package-price-tag num-font">¥</span>
              <span class="package-price-money num-font">{{package_info.sale_price}}</span>
            </div>
            <span class="package-desc">{{package_info.title}}</span>
          </div>
          <img v-if="current_package_id == package_info.id" src="@/assets/images/package_checked.png" alt="">
          <img v-else src="@/assets/images/package_unchecked.png" alt="">
        </li>
      </ul>
    </div>

    <!-- 适用门店 -->
    <div class="shop-container">
      <div class="shop-title">
        <span>适用门店（前{{store_count>3?3:store_count}}家）</span>
        <div @click="gotoPage('/storeList?productno=' + productno)" class="shop-more">查看全部（共{{store_count}}家） <i class="iconfont icon-arrow-right"></i> </div>
      </div>
      <ul class="shop-info">

        <li class="shop-item" v-for="store_info of store_list" :key="store_info.id">
          <StoreItem :store_info="store_info"></StoreItem>
        </li>
      </ul>
    </div>

    <!-- 套餐详情 -->

    <div class="content-container">
      <div class="content-head">
        <span>套餐详情</span>
      </div>
      <ul class="content-list">
        <li class="content-item" v-for="packageInfo of package_list" :key="packageInfo.id">
          <div class="content-title">
            <img src="@/assets/images/point_tag.png" alt="">
            <span>
            {{packageInfo.title}}
          </span>
          </div>
          <ul class="data-list">
            <li class="data-item"  v-for="(packageItem, index) of packageInfo.item_list" :key="index">
              <i class="iconfont icon-arrow-right"></i>
              <span>{{packageItem.content}}</span>
              <span v-if="packageItem.price" class="empty-span"></span>
              <span v-if="packageItem.price" class="old-price">¥{{packageItem.price}}</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <!-- 购买须知 -->
    <ProductNotice :productno="productno"></ProductNotice>

    <!-- 产品详情 -->
    <ProductDetail :productno="productno"></ProductDetail>


  </div>


  <!--底部菜单-->
  <div class="buy-menu">
    <div class="left">
      <div class="menu-item" @click="gotoHome">
        <img src="@/assets/images/home.png">
        <span>主页</span>
      </div>
      <div class="menu-item" @click="shareProduct()">
        <img v-if="isCollection" src="@/assets/images/collection_ok.png" alt="分享">
        <img v-else src="@/assets/images/share.png" alt="分享">
        <span>分享</span>
      </div>
      <div class="menu-item" @click="showContact()">
        <img src="@/assets/images/kefu2.png">
        <span>客服</span>
      </div>
    </div>
    <div v-if="product_info.status == 1" @click="gotoBuy(productno, current_package_id)" class="right">
      <span>立即购买</span>
    </div>
    <div v-else class="can-not-buy">
      <span v-if="product_info.status == 0">产品未上架</span>
      <span v-if="product_info.status == 2">产品已售罄</span>
      <span v-if="product_info.status == 3">产品已下架</span>
    </div>

  </div>


  <teleport to="body">

    <div @click="hideModel" v-if="isShowContact" class="contact-mask">
    </div>
    <div v-if="isShowContact" class="contact-container">
      <img class="contact-img" :src="contact_img">
    </div>

  </teleport>


  <teleport to="body">

    <div @click="hideShare" v-if="isShowShare" class="contact-mask">
    </div>
    <div v-if="isShowShare" class="share-container">
      <img class="share-img" :src="fans_poster">
      <div class="share-tip">长按图片，保存海报</div>
    </div>

  </teleport>



</template>

<script>

import {useRoute,useRouter } from 'vue-router';

import ProductSwiper from "../components/ProductSwiper";
import ProductNotice from "../components/ProductNotice";

import {reactive, ref} from "vue";
import BuyerSwiper from "../components/BuyerSwiper";
import StoreItem from "../components/StoreItem";

import api from "../api"
import {fenToYuan, isEmptyStr} from "../util/util";

import ProductDetail from "../components/ProductDetail";
import wx from "weixin-js-sdk";

export default {
  name: "Product",
  components: {ProductDetail, StoreItem, BuyerSwiper, ProductNotice, ProductSwiper},
  setup(){

    const route = useRoute ()
    let productno = ref(route.query.productno)

    //接收参数 并 缓存(通过扫码产品二维码进入该页面时，会有siteno和fansno参数)
    let siteno = ref(route.query.siteno);
    if(!isEmptyStr(siteno.value)){
      localStorage.setItem("tuan_siteno", siteno.value)
    }
    else {
      siteno.value = localStorage.getItem("tuan_siteno")
    }

    if(!isEmptyStr(route.query.fansno)){
      localStorage.setItem("tuan_fansno", route.query.fansno)
    }


    const router = useRouter()

    function gotoHome(){
      router.replace('/home')
    }

    function gotoPage(path){
      router.push(path)
    }

    function gotoBuy(product_no,current_package_id){
      router.push(`/buy?productno=${product_no}&packageid=${current_package_id}`)
    }


    //产品详情
    const product_info = reactive({
    });
    let isCollection = ref(false)
    let current_product_sale_price = ref()
    let current_product_shop_price = ref()

    api.getProduct({
      productno:productno.value
    }).then(rsp => {
      product_info.status = rsp.status
      product_info.sale_price_min = fenToYuan(rsp.sale_price_min)
      product_info.old_price_min = fenToYuan(rsp.old_price_min)
      product_info.all_sale_num = rsp.all_sale_num
      product_info.tag_list = JSON.parse(rsp.tag_json)
      product_info.title = rsp.title
      product_info.description = rsp.description
      product_info.cover_pic = rsp.cover_pic
      product_info.detail = rsp.detail
      isCollection.value = rsp.is_collection

      current_product_sale_price.value = product_info.sale_price_min
      current_product_shop_price.value = product_info.old_price_min




      //设置分享功能
      let current_url = window.location.href
      api.getJsapiData(current_url).then(rsp => {

        //加载微信config begin:
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: rsp.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
          timestamp: rsp.timestamp, // 必填，生成签名的时间戳
          nonceStr: rsp.nonceStr, // 必填，生成签名的随机串
          signature: rsp.signature,// 必填，签名，见附录1
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'previewImage',
            'getLocalImgData',
            'downloadImage',
            'uploadImage',
            'chooseImage',
            'openLocation',
            'getLocation',
            'hideMenuItems'
          ]
          // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });

        // config信息验证成功会执行ready函数
        wx.ready(() => {
          console.log("This is wx.ready.")

          let share_url = 'https://a.025123.com/tuan_app/wx/webview/initUrl?siteno=' + siteno.value + '&utype=2&pno=' + productno.value + '&fno=' + localStorage.getItem("tuan_fansno")

          //分享给好友
          wx.updateAppMessageShareData({
            title: product_info.title, // 分享标题
            desc: site_name.value + '，汇聚全城爆品！', // 分享描述
            link: share_url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: product_info.cover_pic, // 分享图标
            success: function () {
              console.log("updateAppMessageShareData succ")
              // 设置成功
            }
          });


          // ------------"分享到朋友圈"
          wx.updateTimelineShareData({
            title: product_info.title, // 分享标题
            link: share_url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: product_info.cover_pic, // 分享图标
            success: function () {
              console.log("updateTimelineShareData succ")
              // 设置成功
            }
          });


        })

        // config信息验证失败会执行error函数
        wx.error(function (res) {
          console.log("This is wx.error.")
          console.log(res)

        });

        //加载微信config end

      })







    })

    //产品套餐列表
    const package_list = reactive([]);
    let current_package_id = ref()

    api.getProductPackageList({
      productno:productno.value
    }).then(rsp => {

      package_list.splice(0, package_list.length)

      package_list.push(...rsp)

      current_package_id.value = package_list[0].id

      for(var package_info of package_list) {
        package_info.sale_price = fenToYuan(package_info.sale_price)
        package_info.old_price = fenToYuan(package_info.old_price)

        package_info.item_list = JSON.parse(package_info.items_json)
      }


    })


    function selectPackage(package_id){

      current_package_id.value = package_id

      for(var package_info of package_list) {
        if(package_info.id == package_id){
          current_product_sale_price.value = package_info.sale_price
          current_product_shop_price.value = package_info.old_price
        }
      }

    }

    //商户的门店信息
    const store_list = reactive([]);
    let store_count = ref(0)
    api.getStoresByProduct({
      productno: productno.value,
      num: 3
    }).then(rsp => {

      store_list.push(...rsp.store_list)
      store_count.value = rsp.count
    })





    function collectionProduct(){

      if(isCollection.value){
        api.updateCollection({
          productno: productno.value,
          action_type: 'cancel'
        })
      }
      else {
        api.updateCollection({
          productno: productno.value,
          action_type: 'collect'
        })
      }

      isCollection.value = !isCollection.value
    }



    let contact_img = ref()
    let site_name = ref('')
    api.getSiteInfo().then(response => {
      contact_img.value = response.kefu_pic
      site_name.value = response.title
    })

    let isShowContact = ref(false)
    function showContact(){
      isShowContact.value = true
    }

    function hideModel(){
      isShowContact.value = false
    }





    let fans_poster = ref()
    function shareProduct(){
      console.log("this is shareProduct: 99999" )
      console.log(productno.value)

      api.getProductPoster({
        productno: productno.value
      }).then(rsp => {

        fans_poster.value = rsp

        console.log("sherwin 123 ===============")
        console.log(fans_poster.value)

        showShare()

      })
    }

    // 二进制转base64
    // function arrayBufferToBase64(buffer) {
    //   var binary = ''
    //   var bytes = new Uint8Array(buffer)
    //   var len = bytes.byteLength
    //   for (var i = 0; i < len; i++) {
    //     binary += String.fromCharCode(bytes[i])
    //   }
    //   return window.btoa(binary)
    // }



    let isShowShare = ref(false)

    function hideShare(){
      isShowShare.value = false
    }

    function showShare(){
      isShowShare.value = true
    }




    //统计浏览数据
    api.viewEvent({
      productno: productno.value,
    }).then(rsp => {
      console.log("viewEvent succ", "rsp", rsp)
    })

    return {
      isCollection,
      collectionProduct,
      shareProduct,
      gotoPage,
      gotoHome,
      gotoBuy,
      productno,
      current_product_sale_price,
      current_product_shop_price,
      product_info,
      package_list,
      current_package_id,
      selectPackage,
      store_list,
      store_count,
      isShowContact,
      showContact,
      hideModel,
      contact_img,
      hideShare,
      isShowShare,
      showShare,
      fans_poster
    }
  }
}
</script>

<style scoped>

.product-container{
  margin-bottom: 62px;
}


/********** 头部样式 **********/
.head{

  display: flex;
  flex-direction: column;
  padding: 16px 16px 0px 16px;

  position: relative;
  z-index: 2;
  margin-top: -8px;

  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}

.price-container{
  box-sizing: content-box;
  display: flex;
  align-items: flex-end;
}

.price-container :nth-child(5){
  flex: 1;
}

.price{
  font-size: 26px;
  line-height: 26px;
  font-weight: 600;
  color: #fa3c1f;
  margin-left: 5px;
}

.price-tag{
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #fa3c1f;
}

.shop-price{
  font-size: 9px;
  margin-left: 10px;
  margin-bottom: 4px;
  background-color: #fa4125;
  color: #ffffff;
  padding: 0 4px;
  border-radius: 3px;
  text-decoration: line-through;
}

.sell-num{
  font-size: 12px;
  color: #666666;
  margin-bottom: 2px;
  margin-left: 10px;
}

.collection{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 28px;
}

.collection img{
  width: 20px;
  height: 20px;
}

.tag{
  display: flex;
  margin-top: 8px;
}

.tag li{
  color: #fa3c1e;
  font-size: 10px;
  line-height: 16px;
  padding: 0 12px;
  border: 1px solid #fa3c1e;
  border-radius: 2px;
  margin-right: 6px;
}

.title{
  font-size: 18px;
  color: #030303;
  font-weight: 600;
  margin-top: 18px;
  letter-spacing: 1px;
}

.info{
  font-size: 12px;
  color: #999999;
  letter-spacing: 1px;
  margin-top: 4px;
  margin-bottom: 8px;
  line-height: 20px;

}


/********** 购买者样式 **********/

/*选择套餐*/
.package-container{
  margin-top: 8px;
  padding: 16px;
  width: 100%;
  background-color: #ffffff;
}

.package-container>span{
  font-size: 16px;
  color: #000000;
}

.packages{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.package-item{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  margin-top: 12px;
  padding: 0 20px;
}

.selected{
  border: 1px solid #fa3c1e;
}

.package-item img{
  width: 20px;
  height: 20px;
}

.package-info{
  display: flex;
  flex-direction: column;
}

.package-price{
  display: flex;
  align-items: baseline;
}

.package-price-tag{
  font-size: 12px;
  font-weight: 600;
  color: #fa3c1f;
  margin-right: 2px;
}

.package-price-money{
  font-size: 18px;
  font-weight: 700;
  color: #fa3c1f;
}

.package-desc{
  font-weight: 600;
  font-size: 12px;
}

/********* 适用门店 ***********/
.shop-container{
  margin-top: 8px;
  padding: 16px;
  width: 100%;
  background-color: #ffffff;
}

.shop-title{
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.shop-title :nth-child(1){
  font-size: 16px;
}

.shop-title :nth-child(2){
  font-size: 14px;
  color: #666666;
}

.shop-more{
  display: flex;
  align-items: center;
}

.shop-info{
  display: flex;
  flex-direction: column;
}

.shop-item{
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d2d2d2;
}


.shop-item{
  border-bottom: none;
  padding-bottom: 0;
}


/*底部的购买菜单*/
.buy-menu{
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 540px;
  min-width: 320px;
  height: 60px;
  background-color: #ffffff;
  box-shadow:0px -2px 2px 0px rgba(228,228,228,0.8);
  z-index: 5;
  /*margin-bottom: 60px;*/
}

.left{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  margin-left: 30px;
  font-size: 10px;
}

.menu-item{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-item img{
  width: 18px;
  margin-bottom: 4px;
}

.right{
  display: flex;
  justify-content: right;
}

.right span{
  width: 120px;
  height: 36px;
  line-height: 36px;
  color: #fcfdf8;
  font-size: 14px;
  font-weight: 600;
  background-color: #ff4b3c;
  text-align: center;
  margin-right: 20px;
  border-radius: 10px;
}


.can-not-buy{
  display: flex;
  justify-content: right;
}

.can-not-buy span{
  width: 120px;
  height: 36px;
  line-height: 36px;
  color: #fcfdf8;
  font-size: 14px;
  font-weight: 600;
  background-color: #999999;
  text-align: center;
  margin-right: 20px;
  border-radius: 10px;
}

/*产品详情*/
::-webkit-scrollbar{
  display:none
}

.detail-container{
  margin-top: 8px;
  padding: 16px;
  width: 100%;
  /*background-color: #ffffff;*/
}

.container-head{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.container-head span{
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  background: url("../assets/images/base.png") bottom center no-repeat;
  background-size: 100%;
  color: #2a2a2a;
}

.detail-container img{
  width: 100%;
}


/*详情信息*/
.detail-info{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detail-info span{
  margin-top: 10px;
  color: #222222;
  padding-left: 2px;
  padding-right: 2px;
  letter-spacing: 1px;
  /*margin-bottom: 0px; */
  /*font-size: 16px; */
  /*background-color: #999999; */
  /*font-weight: 600;*/
}

.detail-info img{
  margin-top: 6px;
}


/*********** 套餐详情 ************/
.content-container{
  margin-top: 8px;
  padding: 10px 16px 16px 16px;
  width: 100%;
  background-color: #ffffff;
}

.content-head{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -12px;
}

.content-head span{
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  background: url("../assets/images/base.png") bottom center no-repeat;
  background-size: 100%;
  color: #2a2a2a;
}

.content-list{
  display: flex;
  flex-direction: column;
}

.content-item{
  display: flex;
  flex-direction: column;
}

.content-title{
  box-sizing: border-box;
  display: flex;
  align-items: start;
  color: #010101;
  font-size: 14px;
  margin-top: 30px;
}

.content-title img{
  width: 8px;
  height: 8px;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 6px;
}

.data-list{
  display: flex;
  flex-direction: column;
}

.data-item{
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  font-size: 12px;
  color: #333333;
  margin-top: 10px;
}

.data-item i{
  margin-left: 12px;
  margin-right: 2px;
  color: #fa3c1f;
  font-size: 12px;
}

.empty-span{
  flex: 1;
}

.old-price{
  font-size: 12px;
  font-weight: 500;
  margin: -2px 10px 0px 20px;
}


/*客服二维码*/
.contact-mask{
  position: fixed;
  top: 0;bottom: 0;left: 0;right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}

.contact-container{
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 540px;
  min-width: 320px;
  max-height: 400px;
  z-index: 11;
  border-radius: 10px;
  overflow: hidden;
}

.contact-img{
  min-width: 100%;
  min-height: 100%;
}

.share-container{

  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  z-index: 11;
  border-radius: 10px;
  overflow: hidden;
}

.share-img{
  width: 280px;
  border-radius: 6px;
}


.share-tip{
  width: 280px;
  margin-top: 20px;
  font-size: 20px;
  color: #ffffff;
  background-color: #07c160;
  line-height: 46px;
  text-align: center;
  border-radius: 6px;

}


</style>
