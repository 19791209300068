//引入Vuex
import {createStore} from 'vuex'

import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import state from "./state";


//创建并暴露store
export default createStore({
	state,
	mutations,
	actions,
	getters
})
