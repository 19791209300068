<template>

  <h1>This is error page.</h1>

</template>

<script>
export default {
  name: "Error"
}
</script>

<style scoped>

</style>
