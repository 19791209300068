<template>
  <div class="notify-container">
    <img src="../../assets/images/success.png" alt="">
    <span class="notify-txt">购买成功</span>
  </div>

  <div class="back-container" @click="gotoOrders()">
    <span class="back-btn">返回订单列表</span>
  </div>

  <div class="ad-container">
    <div class="ad-txt">
<!--      <i class="iconfont icon-xiaolaba"></i>-->
      <span>关注下方公众号，随时查看订单</span>
    </div>
    <img :src="wechat_public_logo" alt="">
  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {ref} from "vue";
import api from "../../api";

export default {
  name: "PaySuccess",
  setup(){

    const router = useRouter()
    const route = useRoute ()
    let status = ref(route.query.status)
    let wechat_public_logo = ref('')
    api.getSiteInfo().then(rsp => {
      wechat_public_logo.value = rsp.wechat_public_logo
    })



    function gotoOrders(){
      router.push('/orders?status=' + status.value)
    }

    return {
      gotoOrders,
      wechat_public_logo
    }

  }
}
</script>

<style scoped>

.notify-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding-top: 30px;
  padding-bottom: 20px;
}

.back-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.back-btn{
  color: #ffffff;
  background-color: #09ba07;
  width: 80%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 8px;
  font-size: 20px;
}

.notify-txt{
  font-size: 26px;
  margin-top: 10px;
}


.ad-txt{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  background-color: #fdf6ec;
  color: #e6a23c;
  margin-top: 20px;
}

.ad-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ad-txt i{
  margin-right: 10px;
}

.ad-container img{
  width: 80%;
}

</style>
