<template>

<!--  修改预留信息-->
  <div class="chg-container">
    <div class="head">修改预留信息</div>

    <div class="user-container">
      <span class="user-title">使用者信息</span>
      <div class="user-info">
        <span>姓名<i class="iconfont icon-iov-required"></i></span>
        <input type="text" placeholder="请输入姓名" ref="keywordInput" v-model="user_name">
      </div>
      <div class="user-info">
        <span>手机号<i class="iconfont icon-iov-required"></i></span>
        <input type="text" placeholder="请输入手机号" ref="keywordInput" v-model="user_phone">
      </div>
      <div class="user-info">
        <span>备注</span>
        <input type="text" placeholder="请输入备注" ref="keywordInput" v-model="user_remark">
      </div>
    </div>

    <div class="msg" v-if="isShowErrorMsg">{{errorMsg}}</div>

    <div class="notify" v-if="isShowNotify">修改成功，即将返回</div>

  </div>


  <div class="submit-container">
    <div class="submit-btn" @click="chgUser()">
      <span>修改</span>
    </div>

  </div>

</template>

<script>

import {useRoute, useRouter} from 'vue-router'

import {ref} from "vue";

import api from "../../api"

export default {
  name: "ChangeUser",
  setup(){

    const route = useRoute ()
    const router = useRouter()

    let orderItemId = ref(route.query.orderItemId)
    console.log(orderItemId)


    let isShowNotify = ref(false)
    let isShowErrorMsg = ref(false)
    let errorMsg = ref("")

    let user_name = ref("")
    let user_phone = ref("")
    let user_remark = ref("")


    api.getOrderItemById({
      orderItemId: orderItemId.value
    }).then(rsp => {
      user_name.value = rsp.user_name
      user_phone.value = rsp.user_phone
      user_remark.value = rsp.user_remark
    })

    function chgUser(){

      api.chgUser({
        orderItemId: orderItemId.value,
        user_name: user_name.value,
        user_phone: user_phone.value,
        user_remark: user_remark.value
      }).then(rsp => {

        //修改失败
        if(rsp.code == 1){
          isShowErrorMsg.value = true
          errorMsg.value = rsp.message
        }
        //成功后，给出提示，然后跳走
        else if(rsp.code == 0){
          isShowNotify.value = true
          setTimeout(function(){
            router.go(-1)
          },2000);

        }

      })

    }

    return {
      isShowErrorMsg,
      errorMsg,
      isShowNotify,
      chgUser,
      user_name,
      user_phone,
      user_remark
    }

  }
}
</script>

<style scoped>


.chg-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
}

.head{
  font-size: 16px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #ededed;
}


/*使用者信息*/
.user-container{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  z-index: 99;
}

.user-title{
  font-size: 16px;
  font-weight: 500;
  padding: 0 4px;
  height: 30px;
  line-height: 30px;
}

.user-info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #eeeeee;
}

.user-info:last-child{
  border-bottom: none;
}



.user-info input{
  width: 66%;
  height: 30px;
  border: none;
}

.user-info i{
  color: #ff4b3c;
}

.msg{
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #ff4b3c;
  text-align: center;
}

.notify{
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  width: 200px;
  height: 120px;
  line-height: 120px;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  z-index: 666666;
}

/*底部的提交按钮*/
.submit-container{
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  min-width: 320px;
  height: 70px;
  background-color: #ffffff;
  box-shadow:0px -2px 2px 0px rgba(228,228,228,0.8);
  z-index: 5;
  /*margin-bottom: 60px;*/
}

.submit-btn{
  width: 90%;
  height: 50px;
  background-color: #ff4b3c;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fcfdf8;
  font-size: 16px;
  font-weight: 600;
}

</style>
