<template>

  <div class="buyer-container" v-if="buyer_list.length > 8">
    <div class="buyer-title">
      <span>已经购买的小伙伴</span>
      <span>在这里，吃喝玩乐更省钱！</span>
    </div>

  <swiper class="swiper-container swiper-no-swiping"
          :speed="swiperOptions.speed"
          direction="vertical"
          :breakpoints="swiperOptions.breakpoints"
          :autoplay="swiperOptions.autoplay"
          :slides-per-view="1"
          :space-between="50"
          loop
  >
    <swiper-slide class="swiper-slide" v-for="buyer in buyer_list" :key="buyer.id">
      <div class="item-container">
        <div class="buyer-item">
          <img :src="buyer.header_pic" alt="头像">
          <div class="buyer-info">
            <div class="buyer-name">
              <span>{{buyer.name}}</span><span>手机 {{buyer.phone}}</span>
            </div>
            <span class="buyer-desc">购买了此商品</span>
          </div>
          <div>&nbsp;</div>
          <div  @click="gotoBuy(productno, packageid)" class="buyer-buy">马上抢</div>
        </div>
      </div>
    </swiper-slide>
  </swiper>

  </div>
</template>

<script>

//引入swiper组件
import { Swiper, SwiperSlide } from 'swiper/vue';

//引入swiper样式（swiper6的样式引入方法）
import "swiper/swiper-bundle.css";
import SwiperCore, {Navigation, Scrollbar, A11y, Autoplay} from "swiper";

//使用swiper组件
SwiperCore.use([Navigation, Scrollbar, A11y, Autoplay]);

import api from "../api"
import {reactive} from "vue";

import {useRouter } from 'vue-router';

export default {
  name: "BuyerSwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  props:{
    productno:String,
    packageid:Number
  },
  setup(props) {

    const router = useRouter()

    const buyer_list = reactive([])

    api.getBuyerListForShow({
      productno: props.productno
    }).then(rsp => {
      buyer_list.push(...rsp)
    })


    const swiperOptions = {
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 0
        }
      },
      autoplay:{
        delay: 1800,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      speed:600
    }

    function gotoBuy(product_no,packageid){
      router.push(`/buy?productno=${product_no}&packageid=${packageid}`)
    }

    return {
      modules: [Navigation, Scrollbar, A11y],
      swiperOptions,
      gotoBuy,
      buyer_list
    };
  },
}
</script>

<style scoped>

.buyer-container{
  margin-top: 8px;
  padding: 16px 16px 0 16px;
  width: 100%;
  background-color: #ffffff;
}

.buyer-title{
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.buyer-title :nth-child(1){
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
  line-height: 16px;
}

.buyer-title :nth-child(2){
  color: #797979;
  font-size: 14px;
  line-height: 14px;
}


/***************** swiper ******************/

.swiper-container {
  width: 100%;
  height: 120px;
  overflow: hidden;
  --swiper-theme-color: #fff;
}


.swiper-slide {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.item-container{
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
}

.buyer-item{
  display: flex;
  align-items: center;
  width: 100%;
}

.buyer-item img{
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin-right: 10px;
}

.buyer-info{
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 150px;
}

.buyer-name{
  display: flex;
  color: #202020;
  font-size: 14px;
  justify-content: space-between;
}

.buyer-desc{
  color: #777777;
  font-size: 14px;
  text-align: left;
}

.buyer-item :nth-child(3){
  flex: 1;
}

.buyer-buy{
  padding: 4px 16px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  background: linear-gradient(to right, #fa4125, #f94222);
  border-radius: 14px;

}


</style>
