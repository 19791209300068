<template>

  <div class="header">
    <span class="title">红包余额</span>
    <div class="money">
      <span class="money-tag num-font">¥</span>
      <span class="money-amount">0.00</span>
    </div>
  </div>

  <div class="type">
    <div class="current" :class="{active:redpacket_type==0}" @click="chgRedpacketType()">
      <span class="desc">可用红包</span>
      <span class="selected-line"></span>
    </div>
    <div class="history" :class="{active:redpacket_type==1}" @click="chgRedpacketType()">
      <span class="desc">历史红包</span>
      <span class="selected-line"></span>
    </div>
  </div>

  <div class="data-container">
    <div class="data-list">

    </div>
    <div class="no-data">
      <img src="https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_no_redpacket.png">
      <span class="no-data-desc">暂时没有红包</span>
    </div>
  </div>

</template>

<script>
import {ref} from "vue";

export default {
  name: "RedPacket",
  setup(){
    let redpacket_type = ref(0)  //0可用红包， 1历史红包

    function chgRedpacketType(){
      if(redpacket_type.value == 0){
        redpacket_type.value = 1
      }
      else {
        redpacket_type.value = 0
      }
    }

    return {
      redpacket_type,
      chgRedpacketType
    }

  }
}
</script>

<style scoped>

/*********** header ***********/
.header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 8px;
  height: 130px;
  color: #ffffff;
  background: linear-gradient(to right, #fd4b2f, #ff4041);
  box-shadow:0px 0px 2px 1px rgba(255,156,35,0.3);
}

.title{
  font-size: 14px;
}

.money{
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.money-tag{
  font-size: 24px;
  font-weight: 500;
  margin-right: 8px;
}

.money-amount{
  font-size: 40px;
  font-weight: 600;
}

/*********** type ***********/
.type{
  display: flex;
  justify-content: space-between;
  margin: 10px;
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
}

.current{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-left: 40px;
}

.history{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-right: 40px;
}

.desc{
  margin-bottom: 6px;
}

.active .desc{
  color: #ec4648;
}

.selected-line{
  display: inline-block;
  width: 30px;
  height: 1px;
}

.active .selected-line{
  background-color: #ec4648;
}

/*********** data-container ***********/
.data-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.no-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 150px;
}

.no-data img{
  width: 50%;
}

.no-data-desc{
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
}


</style>
