<template>
  <div class="detail-container">

    <div class="container-head">
      <span>产品信息</span>
    </div>

    <div class="detail-info" v-html="product_detail">


    </div>

  </div>
</template>

<script>
import {ref} from "vue";
import api from "../api";

export default {
  name: "ProductDetail",
  props:{
    productno:String
  },
  setup(props){


    let product_detail = ref("")

    api.getProductDetailItems({
      productno:props.productno
    }).then(rsp => {

      let item_list = []

      for(let oneLine of rsp)
      {
        item_list.push(oneLine.element_info)
      }

      product_detail.value = item_list.join("")

    })


    return {
      product_detail

    }

  }
}
</script>

<style scoped>

/*产品详情*/
::-webkit-scrollbar{
  display:none
}

.detail-container{
  margin-top: 8px;
  padding: 16px;
  width: 100%;
  /*background-color: #ffffff;*/
}

.container-head{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.container-head span{
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  background: url("../assets/images/base.png") bottom center no-repeat;
  background-size: 100%;
  color: #2a2a2a;
}

.detail-container img{
  width: 100%;
}


/*详情信息*/
.detail-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.detail-info span{
  margin-top: 10px;
  color: #222222;
  padding-left: 2px;
  padding-right: 2px;
  letter-spacing: 1px;
}

.detail-info img{
  margin-top: 6px;
}

</style>
