<template>

  <div class="content-container">
    <div class="content-head">
      <span>购买须知</span>
    </div>
    <ul class="content-list">

      <li class="content-item" v-for="notice_info of notice_list" :key="notice_info.id">
        <div class="content-title">
          <img src="@/assets/images/point_tag.png" alt="">
          <span>
            {{notice_info.title}}
          </span>
        </div>
        <ul class="data-list">
          <li class="data-item"  v-for="(item_info, index) of notice_info.item_list" :key="index">
            <i class="iconfont icon-arrow-right"></i>
            <span>{{item_info}}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>

</template>

<script>
import {reactive} from "vue";
import api from "../api";

export default {
  name: "ProductNotice",
  props:{
    productno:String
  },
  setup(props){

    const notice_list = reactive([])

    api.getProductNoticeList({
      productno:props.productno
    }).then(rsp=>{
      notice_list.splice(0, notice_list.length)
      notice_list.push(...rsp)
      for(let notice_info of notice_list) {
        notice_info.item_list = JSON.parse(notice_info.notices_json)
      }
    })

    return {
      notice_list
    }

  }
}
</script>

<style scoped>

.content-container{
  margin-top: 8px;
  padding: 10px 16px 16px 16px;
  width: 100%;
  background-color: #ffffff;
}

.content-head{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -12px;
}

.content-head span{
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  background: url("../assets/images/base.png") bottom center no-repeat;
  background-size: 100%;
  color: #2a2a2a;
}

.content-list{
  display: flex;
  flex-direction: column;
}

.content-item{
  display: flex;
  flex-direction: column;
}

.content-title{
  box-sizing: border-box;
  display: flex;
  align-items: start;
  color: #010101;
  font-size: 14px;
  margin-top: 30px;
}

.content-title img{
  width: 8px;
  height: 8px;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 6px;
}

.data-list{
  display: flex;
  flex-direction: column;
}

.data-item{
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  color: #333333;
  margin-top: 10px;
}

.data-item i{
  margin-left: 12px;
  margin-right: 2px;
  color: #fa3c1f;
  font-size: 12px;
}

</style>
