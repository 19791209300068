
import {createRouter, createWebHistory} from 'vue-router'

import routes from "./routes";

const router = createRouter({
	history: createWebHistory(),
	// 所有路由
	routes
})

// 切换路由时，都回到顶部
router.afterEach(() => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
})

export default router
