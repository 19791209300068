<template>
<!--  搜索页-->

  <div class="search-container">
    <i @click="gotoPre" class="back-tag iconfont">&#xe658;</i>
    <div class="input-container">
      <input type="text" placeholder="搜你喜欢！" ref="keywordInput" v-model="keyword">
      <i v-if="keyword.length > 0" class="del-tag iconfont" @click="clearKeyword">&#xe651;</i>
    </div>
    <span class="search-btn" @click="searchProducts">搜索</span>
  </div>

  <div v-if="!haveSearch&&keyword_history_list.length>0" class="search-history">
    <div class="history-title">
      <span>搜索历史</span>
      <i @click="clearSearchHistory" class="iconfont">&#xe6b4;</i>
    </div>
    <ul class="history-item">
      <li v-for="(history_keyword, index) of keyword_history_list" :key="index" @click="searchHistory(history_keyword)">{{ history_keyword }}</li>
    </ul>
  </div>

  <div v-if="haveSearch&&productList.length==0" class="no-data">
    <img src="@/assets/images/no_data.png">
    <span>暂时没找到您要的东西哦</span>
  </div>


  <!--产品列表-->
  <div v-if="haveSearch" class="product-container">

    <span v-if="haveSearch&&productList.length>0">共为您找到{{total_num}}条数据。</span>

    <ProductItem v-for="productInfo of productList" :key="productInfo.id" :product="productInfo"></ProductItem>

  </div>

</template>

<script>
import {ref, onMounted, reactive, onUnmounted} from 'vue'
import ProductItem from "../components/ProductItem";
import {useRouter} from 'vue-router'
import api from "../api";
import {isEmptyStr,fenToYuan} from "../util/util";

export default {
  name: "Search",
  components: {
    ProductItem
  },
  setup(){

    //标记是否已经搜索：false 未搜索， true 已经搜索
    let haveSearch = ref(false)

    let keyword = ref('')
    let current_keyword = ref('')  //记录当搜索的关键词，用作分页

    function clearKeyword(){
      keyword.value = ''
      haveSearch.value = false
    }

    const router = useRouter()

    function gotoPre(){
      router.go(-1);
    }

    //读取历史搜索信息
    const keyword_history_list = reactive([])
    let history_str = localStorage.getItem("tuan_keyword_history")
    if(!isEmptyStr(history_str)){
      let a = JSON.parse(history_str)
      keyword_history_list.push(...a)
    }

    function clearSearchHistory(){
      keyword_history_list.splice(0, keyword_history_list.length)
      localStorage.removeItem("tuan_keyword_history")
    }

    const productList = reactive([])

    function searchProducts(){

      //如果换了关键词，则从第一页开始取数据
      if(current_keyword.value != keyword.value)
      {
        offset_page.value = 0
      }

      //如果当前页是第一页(页码为0)，则清空原有数据； 否则，不能清空(为加载更多数据)
      if(offset_page.value == 0)
      {
        productList.splice(0, productList.length)
      }

      current_keyword.value = keyword.value

      //标记已经触发搜索
      haveSearch.value = true

      //保存搜索记录
      if(!isEmptyStr(keyword.value) && keyword_history_list.indexOf(keyword.value)==-1)
      {
        keyword_history_list.push(keyword.value)
        localStorage.setItem("tuan_keyword_history", JSON.stringify(keyword_history_list))
      }

      api.searchProductPageList({
        key_word: keyword.value,
        offset:offset_page.value * page_size.value,
        pageSize:page_size.value
      }).then(rsp => {
        productList.push(...rsp.list)

        for(var product of productList) {
          product.sale_price_min_yuan = fenToYuan(product.sale_price_min)
          product.old_price_min_yuan = fenToYuan(product.old_price_min)
        }

        //设置分页的参数
        total_num.value = rsp.total
        no_more_data.value = total_num.value == productList.length
        is_loading.value = false

      })

    }

    function searchHistory(history_keyword){
      keyword.value = history_keyword
      searchProducts()
    }



    const keywordInput = ref(null)
    onMounted(() => {
      keywordInput.value.focus()
    })



    //实现触底加载数据的功能 begin ***************************************
    let is_loading = ref(false)   //是否正在加载数据：false 没有， true 正在加载

    let no_more_data = ref(false)  //没有有更多数据：false 有更多数据，true 没有更多数据
    let total_num = ref(0)        //总数量
    let offset_page = ref(0)      //当前页码
    let page_size = ref(10)       //每页数量



    onMounted(()=>{
      console.log('---onMounted---')
      window.addEventListener('scroll', reachBottom);  //页面加载时监听滚动事件
    })

    onUnmounted(()=>{
      console.log('---onUnmounted---')
      window.removeEventListener('scroll', reachBottom) //页面离开后销毁监听事件
    })

    function reachBottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      //距离底部小于50就会触发
      if (scrollTop + clientHeight >= scrollHeight - 50) {

        //开始加载分页数据
        getMoreData()

      }
    }

    function getMoreData() {

      if(no_more_data.value || is_loading.value){
        return
      }

      is_loading.value = true

      //只有总量大于0时，才递增页码 （这里的判断是为了防止，刚进入该页面就调用该方法，即尚未调用getProducts获取第一页数据，就直接调用getMoreData方法）
      if(total_num.value > 0)
      {
        offset_page.value++
      }

      searchProducts()

    }
    //实现触底加载数据的功能 end ***************************************


    return {
      productList,
      keywordInput,
      gotoPre,
      keyword,
      clearKeyword,
      searchProducts,
      haveSearch,
      keyword_history_list,
      clearSearchHistory,
      searchHistory,
      total_num
    }
  }
}
</script>

<style scoped>
/****************** 搜索框 ******************/
.search-container{
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 15px;
  padding-top: 20px;
  padding-right: 20px;;
  z-index: 2;
  background-color: #f6f6f6;
}

.back-tag{
  font-size: 40px;
}

.search-btn{
  width: 48px;
  height: 26px;
  background: linear-gradient(to right, #ffdc4e, #ffc82d);
  color: #000000;
  font-size: 12px;
  border-radius: 25px;
  text-align: center;
  line-height: 26px;
}

.input-container{
  flex: 1;
  display: flex;
  position: relative;
  margin: 0 10px 0 15px;
}

.input-container input{
  height: 30px;
  flex: 1;
  border: 1px solid #fae198;
  border-radius: 17px;
  padding-left: 15px;
  padding-right: 30px;
}

.del-tag{
  position: absolute;
  font-size: 20px;
  z-index: 2;
  right: 6px;
  top: 2px;
}


.search-history{
  display: flex;
  margin: 40px 25px 0 25px;
  flex-direction: column;
  align-items: flex-start;
}

.history-title{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.history-title span{
  font-size: 16px;
  font-weight: 500;
  color: #020202;
  letter-spacing: 1px;
}

.history-title i{
  font-size: 18px;
}

.history-item{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  font-size: 12px;
}

.history-item li{
  padding: 8px 12px;
  background-color: #ffffff;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
}


.no-data{
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  align-items: center;
  color: #666666;
}

.no-data img{
  width: 60%;
  margin-bottom: 30px;
}


.product-container{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.product-container span{
  margin-left: 20px;
}


</style>
