<template>

  <ul class="category-container">
    <li class="category-item-container" @click="gotoPage('/category?category_type=1')">
      <div class="content">
        <img src="@/assets/images/icon_category_1.png">
        <span>餐饮美食</span>
      </div>
    </li>
    <li class="category-item-container" @click="gotoPage('/category?category_type=2')">
      <div class="content">
        <img src="@/assets/images/icon_category_2.png">
        <span>景点游玩</span>
      </div>
    </li>
    <li class="category-item-container" @click="gotoPage('/category?category_type=3')">
      <div class="content">
        <img src="@/assets/images/icon_category_3.png">
        <span>休闲娱乐</span>
      </div>
    </li>
    <li class="category-item-container" @click="gotoPage('/category?category_type=4')">
      <div class="content">
        <img src="@/assets/images/icon_category_4.png">
        <span>酒店民宿</span>
      </div>
    </li>
    <li class="category-item-container" @click="gotoPage('/category?category_type=5')">
      <div class="content">
        <img src="@/assets/images/icon_category_5.png">
          <span>亲子推荐</span>
      </div>
    </li>
  </ul>

</template>

<script>

import {useRouter} from 'vue-router'

export default {
  name: "CategoryMenu",
  props:['currentSelectId'],
  setup(){

    const router = useRouter()

    function gotoPage(path){
      //在菜单页之间的跳转使用replace(不需要返回)，其他页面之前的跳转使用push（需要返回）
      if("/category" === router.currentRoute.value.path)
      {
        router.replace(path)
      }
      else
      {
        router.push(path)
      }
    }

    return {
      gotoPage
    }
  }
}
</script>

<style scoped>

/*分类菜单*/
.category-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.category-item-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*width: 60px;*/
  width: calc((100% - 40px)/5);
  height: 0;
  padding-top: calc((100% - 40px)/5);
  background-color: #fef3d3;
  border-radius: 10px;
}

.content{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content img{
  width: 50%;
  height: 50%;
}

.category-item-container span{
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  margin-top: 4px;
}

.category-container .active{
  background-color: #fefefc;
}

.category-container .active span{
  font-weight: 600;
  color: #f54e3b;
  /*background: url("../assets/images/icon_category_active.png") bottom center no-repeat;*/
  /*background-size: 100%;*/
}

</style>
