<template>
<!--  订单购买页-->

  <div class="buy">
    <div class="product-container">
      <div class="product-info">
        <div class="product-pic" :style="{backgroundImage: 'url(' + product_pic + ')'}"></div>
        <div class="info">
          <span class="title">{{product_title}}</span>
          <span class="desc">{{product_desc}}</span>
        </div>
      </div>
      <div class="num-container">
        <span class="num-tip">数量</span>
        <div class="num-btn">
          <i class="iconfont icon-reduce num-dec" :class="{'forbid': buy_num<2}" @click="decreBuyNum"></i>
          <span class="num-data">{{buy_num}}</span>
          <i class="iconfont icon-add-bold num-inc" :class="{'forbid': buy_num == buy_limit}" @click="increBuyNum"></i>
        </div>
      </div>
      <div class="name-price-container">
        <span class="name">{{package_name}}</span>
        <div class="price">
          <span class="price-tag num-font">¥</span>
          <span class="price-num num-font">{{ package_price_yuan }}</span>
        </div>
      </div>
      <div class="coupon-container">
        <div class="coupon-tip">
          <i class="iconfont icon-youhuiquan"></i>
          <span>优惠券</span>
        </div>
        <span class="coupon-use">暂无可用 <i class="iconfont icon-arrow-right"></i> </span>
      </div>
    </div>
    <div class="user-container">
      <span class="user-title">使用者信息</span>
      <div class="user-info">
        <span>姓名<i class="iconfont icon-iov-required"></i></span>
        <input type="text" placeholder="请输入姓名" ref="keywordInput" v-model="user_name">
      </div>
      <div class="user-info">
        <span>手机号<i class="iconfont icon-iov-required"></i></span>
        <input type="text" placeholder="请输入手机号" ref="keywordInput" v-model="user_phone">
      </div>
      <div class="user-info">
        <span>备注</span>
        <input type="text" placeholder="请输入备注" ref="keywordInput" v-model="user_remark">
      </div>
    </div>
<!--    <div class="invoice-container">-->
<!--      <span class="invoice-title">发票</span>-->
<!--      <span class="invoice-info">订单核销使用后在订单详情申请开具</span>-->
<!--    </div>-->
    <div class="agreement">
      <div class="intro">
        <span class="intro-a">提交订单则表示您同意</span>
        <span class="intro-b">《平台用户服务协议》</span>
      </div>
      <span class="intro-detail">此套餐为限时限量购买产品，请您在有效期内及时使用</span>
    </div>
  </div>

  <div class="buy-footer" v-show="is_show_footer">
    <div class="fee-container">
      <span class="buy-num">共{{buy_num}}件,</span>
      <span class="total-fee"><span>¥</span>{{total_fee_yuan}}</span>
    </div>
    <span class="buy-btn" @click="gotoWxPay(productno,packageid,buy_num,total_fee_fen)">去支付</span>
  </div>

  <!--提示框-->
  <div class="tip-container" v-if="tip_messgae">
    {{tip_messgae}}
  </div>
</template>

<script>

import {useRoute, useRouter} from 'vue-router';
import {ref} from "vue";

import api from "../api"
import {fenToYuan, keepTwoDecimal,isEmptyStr} from "../util/util";

export default {
  name: "Buy",
  setup(){

    const router = useRouter()
    const route = useRoute ()
    let productno = ref(route.query.productno)
    let packageid = ref(route.query.packageid)

    //是否显示底部的购买（当输入时，不显示）
    let is_show_footer = ref(true)

    //产品信息
    let product_pic = ref("")
    let product_title = ref("")
    let product_desc = ref("")
    let use_status = ref(0)
    api.getProduct({
      productno: productno.value
    }).then(rsp => {
      product_pic.value = rsp.cover_pic
      product_title.value = rsp.title
      product_desc.value = rsp.description
      //需要预约 => 订单的状态应该是0:待预约
      if(rsp.is_need_book == 0)
      {
        use_status.value = 0
      }
      //不需要预约 => 订单的状态应该是1:待使用
      else
      {
        use_status.value = 1
      }
    })

    //套餐信息
    let package_name = ref("")
    let package_price_yuan = ref()
    let package_price_fen = ref()
    let buy_num = ref()
    let total_fee_yuan = ref()
    let total_fee_fen = ref()
    let buy_limit = ref()
    api.getProductPackage({
      productno: productno.value,
      packageid: packageid.value
    }).then(rsp => {
      package_name.value = rsp.title
      package_price_yuan.value = fenToYuan(rsp.sale_price)
      package_price_fen.value = rsp.sale_price
      buy_num.value = 1
      total_fee_yuan.value = package_price_yuan.value
      total_fee_fen.value = package_price_fen.value
      buy_limit.value = rsp.buy_limit

    })

    function decreBuyNum(){
      if(buy_num.value > 1)
      {
        buy_num.value--
        total_fee_yuan.value = keepTwoDecimal(buy_num.value * package_price_yuan.value)
        total_fee_fen.value = buy_num.value * package_price_fen.value
      }
      else
      {
        showTip("至少选择1份")
      }
    }

    function increBuyNum(){

      //正常代码
      // if(buy_num.value < buy_limit.value)
      // {
      //   buy_num.value++
      //   total_fee_yuan.value = keepTwoDecimal(buy_num.value * package_price_yuan.value)
      //   total_fee_fen.value = buy_num.value * package_price_fen.value
      // }
      // else
      // {
      //   showTip("最多可选" + buy_limit.value + "份")
      // }

      //由于涉及分账问题，每单只允许购买一个商品（同时购买多份，核销一份时，无法将部分资金解冻给商家）
      if(buy_num.value < 1)
      {
        buy_num.value++
        total_fee_yuan.value = keepTwoDecimal(buy_num.value * package_price_yuan.value)
        total_fee_fen.value = buy_num.value * package_price_fen.value
      }
      else
      {
        showTip("特惠商品，每次只能购买一份")
      }


    }

    let tip_messgae = ref("")
    function showTip(msg){
      tip_messgae.value = msg
      //3秒后消失
      setTimeout(function(){
        tip_messgae.value = ""
      },1500);
    }

    let user_name = ref("")
    let user_phone = ref("")
    let user_remark = ref("")

    //去微信支付
    function gotoWxPay(productno,packageid,buy_num,total_fee_fen){
      if(isEmptyStr(user_name.value))
      {
        showTip("请输入姓名")
        return
      }
      if(isEmptyStr(user_phone.value))
      {
        showTip("请输入手机号")
        return
      }
      //校验手机号是否合法
      var re = /^1\d{10}$/
      if (!re.test(user_phone.value)){
        showTip("手机号不合法")
        return
      }


      api.addOrder({
        productno: productno,
        product_packageid: packageid,
        buy_num: buy_num,
        total_fee: total_fee_fen,
        user_name: user_name.value,
        user_phone: user_phone.value,
        user_remark: user_remark.value
      }).then(rsp => {

        if(0 != rsp.code)
        {
          showTip(rsp.message)
          return
        }

        //发起支付
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
              "appId": rsp.data.appId,
              "timeStamp": rsp.data.timestamp,
              "nonceStr": rsp.data.nonceStr,
              "package": rsp.data.package,
              "signType": "RSA",
              "paySign": rsp.data.paySign
            },
            function(res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                router.replace('/paySuccess?status=' + use_status.value)
              }
            });


      })

      console.log(productno, packageid, buy_num, total_fee_fen)

    }

    return {
      productno,
      packageid,
      product_pic,
      product_title,
      product_desc,
      package_name,
      package_price_yuan,
      package_price_fen,
      buy_num,
      total_fee_yuan,
      total_fee_fen,
      buy_limit,
      decreBuyNum,
      increBuyNum,
      tip_messgae,
      showTip,
      gotoWxPay,
      user_name,
      user_phone,
      user_remark,
      is_show_footer
    }

  }
}
</script>

<style scoped>

/*********** 产品相关 **********/
.buy{
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 10px;
  margin-bottom: 200px;
}

.product-container{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;
}


.product-info{
  display: flex;
  align-items: center;
}

.product-pic{
  width: 75px;
  height: 75px;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 10px;
  background: bottom center no-repeat;
  background-size: cover;
}

.info{
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.title{
  font-size: 14px;
  font-weight: 600;
  color: #1f1f1f;
  margin-bottom: 10px;

  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
  word-break:break-all;
}

.desc{
  font-size: 12px;
  line-height: 20px;
  color: #656565;

  /*超出2行隐藏 + 省略号*/
  height: auto;
  display:-webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
}

/*数量*/

.num-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-top: 10px;
  padding: 0 4px;
  border-bottom: 1px solid #eeeeee;
}

.num-tip{
  font-size: 14px;
  font-weight: 500;
}

.num-btn{
  display: flex;
}

.num-dec{
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 2px solid #ff4b3c;
  border-radius: 6px;
  font-weight: 600;
}

.num-dec{
  color: #ff4b3c;
}

i.num-dec.forbid{
  color: #d8d8d8;
  border-color: #d8d8d8;
}

.num-data{
  font-size: 14px;
  font-weight: 600;
  margin: 0 10px;
}

.num-inc{
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
  background-color: #ff4b3c;
  color: #ffffff;
  border-radius: 6px;
}


i.num-inc.forbid{
  /*color: #d8d8d8;*/
  background-color: #d8d8d8;
}

/*产品信息*/
.name-price-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 4px;
  border-bottom: 1px solid #eeeeee;
}

.name{
  width: 80%;
  font-size: 14px;
  font-weight: 500;
}

.price{
  color: #ff4b3c;
}

.price-tag{
  font-size: 12px;
  font-weight: 600;
  margin-right: 2px;
}

.price-num{
  font-size: 18px;
  font-weight: 600;
}


/*优惠券*/
.coupon-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 4px;
}

.coupon-tip{
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.coupon-tip i{
  font-size: 20px;
  color: #ff4b3c;
  margin-right: 4px;
}

.coupon-tip span{
  height: 18px;
  line-height: 18px;
  font-size: 14px;
  color: #666666;
}

/*使用者信息*/
.user-container{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  z-index: 99;
}

.user-title{
  font-size: 16px;
  font-weight: 500;
  padding: 0 4px;
  height: 30px;
  line-height: 30px;
}

.user-info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #eeeeee;
}

.user-info:last-child{
  border-bottom: none;
}



.user-info input{
  width: 66%;
  height: 30px;
  border: none;
}

.user-info i{
  color: #ff4b3c;
}

/*协议*/
.agreement{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
}

.intro{
  font-size: 14px;
  color: #1f1f1f;
  margin-bottom: 6px;
}

.intro-b{
  color: #1f60e0;
}

.intro-detail{
  font-size: 12px;
  color: #969aa5;
  text-align: center;
}

/*发票*/
.invoice-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: #ffffff;
  height: 50px;
  padding: 0 14px;
  font-size: 14px;
}

.invoice-title{
  font-size: 14px;
  font-weight: 500;
}

/*底部按钮*/
.buy-footer{
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 540px;
  min-width: 320px;
  width: 100%;
  bottom: 0;
  height: 70px;
  background-color: #ffffff;
  border-top: 1px solid #f6f6f6;
}

.fee-container{
  display: flex;
  align-items: baseline;
  margin-left: 20px;
}

.buy-num{
  font-size: 14px;
  color: #808080;
  margin-right: 4px;
}

.total-fee{
  font-size: 20px;
  font-weight: 600;
  color: #ff4b3c;
}

.total-fee span{
  font-size: 10px;
}

.cash-back{
  font-size: 14px;
  color: #06ba03;
  margin-left: 6px;
}

.buy-btn{
  width: 100px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
  /*background: linear-gradient(to right, #ffd751, #fecf43);*/
  background-color: #ff4b3c;
  color: #ffffff;
  margin-right: 20px;
}

/*提示框*/
.tip-container{
  position: fixed;
  top: 260px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background:rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  padding: 10px 16px;
  border-radius: 8px;
  color: #ffffff;


}

</style>
