<template>

  <div class="header">

    <!--城市切换和客服-->
    <Title></Title>

    <!--搜索框-->
    <div @click="gotoSearch" class="search-box">
      <img src="@/assets/images/icon_home_search@3x.png">
      <span class="search-recommend">搜你想搜的!</span>
      <span>&nbsp;</span>
      <span class="search-btn">搜索</span>
    </div>

    <!--分类菜单-->
<!--    <CategoryMenu></CategoryMenu>-->

  </div>

  <div class="header-bottom-outer">
    <div class="header-bottom-inner">

    </div>
  </div>


<!--  <SortMenu></SortMenu>-->

  <div class="for-sorted-menu">
  </div>

</template>

<script>
import Title from "./Title";

import {useRouter} from 'vue-router'

export default {
  name: "FoodHeader",
  components: {Title},
  setup(){

    const router = useRouter()

    function gotoSearch(){
      router.push('/search')
    }

    return {
      gotoSearch
    }
  }
}
</script>

<style scoped>

/*头部*/
/*.header{*/
/*  width: 100%;*/
/*  background-color: #ffc82d;*/
/*  padding: 0 10px;*/
/*}*/

/*.header-bottom-outer{*/
/*  position: relative;*/
/*  width: 100%;*/
/*  height: 24px;*/
/*  background-color: #ffc82d;*/
/*  margin-bottom: -10px;*/
/*}*/


.header{
  width: 100%;
  padding: 0 10px;
  background-color: #f8a406;
}

.header-bottom-outer{
  position: relative;
  width: 100%;
  height: 24px;
  margin-bottom: -10px;
  background-color: #f8a406;
}




.header-bottom-inner{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 12px;
  background-color: #f6f6f6;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

/****************** 搜索框 ******************/
.search-box{
  display: flex;
  align-items: center;
  height: 34px;
  /*margin-top: 4px;*/
  margin: 4px 0 0 0;
  background-color: #fff;

  /*border: 1px solid #ffbe00;*/
  border-radius: 17px;
}

.search-box img{
  width: 20px;
  margin-left: 10px;
}

.search-box :nth-child(3){
  flex: 1;
}

.search-recommend{
  height: 25px;
  font-size: 12px;
  color: #999999;
  line-height: 25px;
  margin-left: 10px;
}

.search-btn{
  width: 48px;
  height: 26px;
  /*background-color: #1f60e1;*/
  /*background-color: #ffc82d;*/
  background: linear-gradient(to right, #f8a406, #f8a406);
  color: #000000;
  /*background-color: #76a3fb;*/
  /*color: #ffffff;*/
  font-size: 12px;
  border-radius: 25px;
  text-align: center;
  line-height: 26px;
  margin-right: 4px;
}

</style>
